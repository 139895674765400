import React,{ useState,useEffect } from "react";
import axios from 'axios';
import Layout from '../components/layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import { makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles((theme) => ({
  root: {
    margin:"1rem 0 1rem 0",
  // border: "5px solid rgb(111,41,97)",
  padding: "0.3rem",
  display: "flex",
  height:"18rem",
  flexDirection: "row",
  justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection:"column", 
      height:'auto'
    },
    borderRadius:"1rem",
  },
  img:{
    margin:"0.5rem",
    display: 'flex',
    minHeight:"15rem"
  },
  cover:{
    [theme.breakpoints.down('xs')]: {
      display:"inline",
      width:"auto"
    },
    borderRadius:"1rem",
    width:"21rem"
  },
  subtitles:{
    margin:"0.2rem"
  },
  details: {
    margin:"0.5rem",
    display: 'flex',
    minHeight:"15rem",
    flexDirection: 'column'
  },
  content: {
    flex: '1 2 auto'
  },
  body:{
    margin:"1rem 0 1rem 0",
  }
}));

const Project =()=>{
  const classes = useStyles();
    const [project, setProject] = useState(null);
    useEffect(() => {
      console.log('inside Component');
    const apiUrl =`https://api-vikashchander.vercel.app/projects`;
     axios({method: 'get',url:apiUrl}).then(res=> setProject([...res.data.Projects]));
    },[setProject]);
  return (
    <Layout>
      <SEO title="Project" />
           <div id='projects'>
           <h3 className="text-centre big-text mx-5 box-underline">
             <FontAwesomeIcon
              icon={faProjectDiagram}
              color="black"
              className="heart mx-2"
            />
            Projects
          </h3>
          </div>
      {
        project==null?<p>loading...</p>:
        project.map((data,index)=>
        <Card key={index} className={classes.root}>
        <img 
            className={classes.cover}
            src={data.Image }
            alt="Live from space album cover"
          />
          <CardContent className={classes.content}>
              <Typography className={classes.header} gutterBottom variant="h5" component="h2">
              {data.Title}
              </Typography>
              <Typography  variant="subtitle1" color="textSecondary">
                {
                  data.Modules.map((element,index) => <Chip key={index} color="primary" className={classes.subtitles} label={element} />)
                }
              </Typography>
              <Typography className={classes.body}>
              {data.Description}
              </Typography>
              <Divider />
          <CardActions >
          <a
                        href={data.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary remove-highlight links"
                      >
                         <img src="https://img.icons8.com/bubbles/50/000000/github.png" alt="vikas"/>
                      </a>
                      {
                        data.live!==undefined&&  
                        <a
                        href={data.live}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary m-3  remove-highlight links"
                      >
                      <img src="https://img.icons8.com/bubbles/50/000000/external-link.png" alt="vikash"/>
                      </a>
                      }
           
          </CardActions> 
          </CardContent>
        </Card>
         ) }  
    </Layout>
  );
}

export default Project;